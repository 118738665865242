import './src/styles/global.css';

export const onRouteUpdate = ({ location }) => {
  if (location.action === 'PUSH') {
    window.scrollTo(0, 0);
  }
};

// Single export for scroll behavior
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Return false to prevent scroll-to-top on same-page anchor links
  if (location.hash) {
    return false;
  }
  
  // Scroll to top for PUSH actions (new routes)
  if (location.action === 'PUSH') {
    return [0, 0];
  }

  // For POP actions (back/forward), restore the previous scroll position
  return getSavedScrollPosition(location) || true;
};

// Add Vector tracking script
export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    (function (e, r) {
      try {
        if (e.vector) return console.log("Vector snippet included more than once.");
        var t = {};
        t.q = t.q || [];
        for (var o = ["load", "identify", "on"], n = function (e) {
          return function () {
            var r = Array.prototype.slice.call(arguments);
            t.q.push([e, r]);
          };
        }, c = 0; c < o.length; c++) {
          var a = o[c];
          t[a] = n(a);
        }
        if (e.vector = t, !t.loaded) {
          var i = r.createElement("script");
          i.type = "text/javascript";
          i.async = !0;
          i.src = "https://cdn.vector.co/pixel.js";
          var l = r.getElementsByTagName("script")[0];
          l.parentNode.insertBefore(i, l);
          t.loaded = !0;
        }
      } catch (e) {
        console.error("Error loading Vector:", e);
      }
    })(window, document);

    window.vector.load("cf013994-97b4-4356-9970-16d17edef138");
  }
};
